import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { mapState as mapPiniaState } from 'pinia';
import componentI18n from '@/i18n/componentI18n';
import transitions from '@/helpers/transitions';
import { useRetroStore } from '@/pinia_stores/retro';
import AddParticipantSlot from '@/components/users/AddParticipantSlot.vue';
import CtaSidebarControl from '@/components/sidebar/CtaSidebarControl.vue';
import Drawer from '@/components/Drawer.vue';
import MemberDrawerItem from '@/components/MemberDrawerItem.vue';
import ModeratorSidebarControl from '@/components/sidebar/ModeratorSidebarControl.vue';
import PrivateTopics from '@/components/topics/PrivateTopics.vue';
import SidebarContainer from '@/components/sidebar/SidebarContainer.vue';
import Topics from '@/components/topics/Topics.vue';
import UserList from '@/components/users/UserList.vue';
export default defineComponent({
    components: {
        AddParticipantSlot,
        CtaSidebarControl,
        Drawer,
        MemberDrawerItem,
        ModeratorSidebarControl,
        PrivateTopics,
        SidebarContainer,
        Topics,
        UserList
    },
    ...componentI18n('components.sidebar'),
    data() {
        return {
            drawersOpened: {
                topics: false,
                group: false,
                participants: true
            }
        };
    },
    computed: {
        ...mapPiniaState(useRetroStore, [
            'currentGroup',
            'currentGroupTopics',
            'currentPosterId',
            'hasMotivation',
            'invitations',
            'isLoaded',
            'isModerator',
            'isParticipant',
            'isRetroUser',
            'isWatcher',
            'ownPrivateTopics',
            'participants',
            'phase',
            'topics',
            'watcher'
        ]),
        ...mapGetters(['session']),
        groupName() {
            return this.currentGroup.name || this.$t('group.default_name');
        },
        groupTopicCount() {
            return this.currentGroupTopics.length;
        },
        topicCount() {
            return this.ownPrivateTopics ? this.ownPrivateTopics.length : 0;
        },
        sortedCurrentGroupTopics() {
            return Array.from(this.currentGroupTopics).sort((t1, t2) => t1.position - t2.position);
        },
        showSidebar() {
            return (this.isLoaded &&
                this.phase !== 'review' &&
                this.phase !== 'finished' &&
                (this.hasMotivation || this.isWatcher));
        },
        showUserTopics() {
            return (this.isParticipant &&
                this.hasMotivation &&
                (this.phase === 'writing' || this.phase === 'posting'));
        },
        sortedInvitations() {
            return [...this.invitations].sort((invitation1, invitation2) => invitation1.email.localeCompare(invitation2.email));
        },
        watcherName() {
            // TODO: There can only be one watcher, the moderator.
            // We need to remove this at some point, but for now [0] will have to do.
            return this.watcher ? `${this.$t('common.moderator')}: ${this.watcher.name}` : null;
        }
    },
    watch: {
        phase(name) {
            if (!this.isWatcher && name === 'writing' && !this.drawersOpened.topics) {
                this.foldAllAndToggleOnly('topics');
            }
            else if (name === 'discussion' && !this.drawersOpened.group) {
                this.foldAllAndToggleOnly('group');
            }
        },
        currentPosterId(id) {
            if (!this.isWatcher && !this.drawersOpened.topics && this.session.id === id) {
                this.foldAllAndToggleOnly('topics');
            }
        }
    },
    methods: {
        ...transitions.fade,
        foldAllAndToggleOnly(key) {
            Object.entries(this.drawersOpened).forEach(([k, v]) => {
                this.drawersOpened[k] = k === key ? !v : false;
            });
        }
    }
});

import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapActions as mapPiniaActions, mapWritableState as mapPiniaWritableState } from 'pinia';
import { useRetroStore } from '@/pinia_stores/retro';
import componentI18n from '@/i18n/componentI18n';
import constants from '@/constants';
import transitions from '@/helpers/transitions';
export default defineComponent({
    ...componentI18n('components.topics.new'),
    data() {
        return {
            selectedType: null,
            description: ''
        };
    },
    computed: {
        ...mapPiniaWritableState(useRetroStore, ['topicBeingEditedId']),
        ...mapPiniaState(useRetroStore, [
            'activity',
            'topicBeingEdited',
            'ownPrivateTopics',
            'maxTopics',
            'isWritingTopic',
            'isEditingTopic',
            'isSomeActionLoading'
        ]),
        loading() {
            return this.isSomeActionLoading;
        },
        editHasNoChanges() {
            return (this.topicBeingEdited &&
                this.description === this.topicBeingEdited.description &&
                this.selectedType === this.topicBeingEdited.type);
        },
        emptyDescription() {
            return !this.description?.trim();
        },
        noTopicType() {
            return !this.selectedType;
        },
        shouldFormBeVisible() {
            return (this.isWritingTopic && !this.topicLimitReached) || this.isEditingTopic;
        },
        submitDisabled() {
            return this.loading || this.editHasNoChanges || this.emptyDescription || this.noTopicType;
        },
        disabledReason() {
            if (this.editHasNoChanges) {
                return this.$t('edit_has_no_changes');
            }
            else if (this.noTopicType) {
                return this.$t('no_topic_type');
            }
            else if (this.emptyDescription) {
                return this.$t('empty_description');
            }
            else {
                return null;
            }
        },
        cancelDisabled() {
            if (this.loading) {
                return true;
            }
            else if (this.topicBeingEdited) {
                return false;
            }
            else {
                return this.description === '' && !this.selectedType;
            }
        },
        selectedActivityColor() {
            return this.activity.topicTypes.find((t) => t.id === this.selectedType)?.color;
        },
        selectedActivityPlaceholder() {
            if (this.selectedType) {
                return this.$t(`activities.${this.activity.id}.topic_types.${this.selectedType}.placeholder`);
            }
            else {
                return this.$t('placeholder');
            }
        },
        maxTopicLength() {
            return constants.MAX_TOPIC_LENGTH;
        },
        charactersRemaining() {
            return this.maxTopicLength - this.description.length;
        },
        showCharactersRemaining() {
            return constants.LENGTH_REMINDER > this.charactersRemaining;
        },
        topicOptions() {
            return this.activity.topicTypes.map((type) => {
                return {
                    value: type.id,
                    label: this.$t(`activities.${this.activity.id}.topic_types.${type.id}.name`),
                    ...type
                };
            });
        },
        topicLimitReached() {
            return this.maxTopics && this.maxTopics <= this.ownPrivateTopics.length;
        }
    },
    watch: {
        topicBeingEdited(newTopicBeingEdited, oldTopicBeingEdited) {
            if (newTopicBeingEdited) {
                if (!oldTopicBeingEdited && !this.loading) {
                    this.description = newTopicBeingEdited.description;
                    this.selectedType = newTopicBeingEdited.type;
                }
            }
            else {
                this.reset();
            }
        }
    },
    mounted() {
        this.selectedType = this.activity.topicTypes[0].id;
        if (this.$refs.textarea) {
            this.$refs.textarea.focus();
        }
    },
    methods: {
        ...mapPiniaActions(useRetroStore, ['createTopic', 'updateTopic']),
        ...transitions.scale,
        handleSubmit() {
            if (!this.submitDisabled) {
                if (this.topicBeingEdited) {
                    this.handleUpdate();
                }
                else {
                    this.createTopic({
                        type: this.selectedType,
                        description: this.description
                    });
                    this.reset(this.selectedType);
                }
            }
        },
        handleUpdate() {
            if (this.description) {
                this.updateTopic({
                    topicId: this.topicBeingEdited.id,
                    newType: this.selectedType,
                    newDescription: this.description
                });
                this.topicBeingEditedId = null;
            }
        },
        handleCancel() {
            this.topicBeingEditedId = null;
            this.reset();
        },
        reset(selectedType = null) {
            this.selectedType = selectedType;
            this.description = '';
        }
    }
});

import { defineComponent } from 'vue';
import { mapState as mapPiniaState } from 'pinia';
import { mapGetters } from 'vuex';
import { event } from 'vue-gtag';
import { useRetroStore } from '@/pinia_stores/retro';
import componentI18n from '@/i18n/componentI18n';
import delightedSurvey from '@/helpers/delightedSurvey';
import { buildRetroMarkdown } from '@/helpers/exporting';
import ActionItems from '@/components/action_items/ActionItems.vue';
import Groups from '@/components/groups/Groups.vue';
export default defineComponent({
    name: 'RetroBrief',
    components: {
        ActionItems,
        Groups
    },
    ...componentI18n('components.brief'),
    computed: {
        ...mapPiniaState(useRetroStore, [
            'actionItems',
            'activity',
            'groups',
            'participants',
            'publicTopics',
            'retroName',
            'startsAt',
            'users'
        ]),
        ...mapGetters(['locale', 'session']),
        url() {
            return `${window.location.origin}${this.$route.path}`;
        }
    },
    mounted() {
        delightedSurvey({
            activity: this.activity.id,
            email: this.session.email,
            name: this.session.name,
            retroId: this.$route.params.retroId
        });
    },
    methods: {
        trackEvent(format) {
            event('export', {
                event_category: 'retro',
                event_label: format
            });
        },
        getMarkdown() {
            return buildRetroMarkdown({
                actionItems: this.actionItems,
                groups: this.groups,
                publicTopics: this.publicTopics,
                users: this.users,
                startsAt: this.startsAt,
                url: this.url,
                locale: this.locale,
                name: this.retroName
            });
        }
    }
});

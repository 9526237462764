<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" v-if="type == 'awesome'" v-bind:svg-inline="''" v-bind:src="'@/assets/images/motivation/awesome.svg'" v-bind:alt="'Awesome'"><g fill="none" fill-rule="evenodd"><path fill="#FFD220" d="M6.706 0h30.588a5.501 5.501 0 015.498 5.328l1.03 33a5.5 5.5 0 01-5.325 5.67l-.172.002H5.675a5.5 5.5 0 01-5.5-5.5c0-.057 0-.115.002-.172l1.031-33A5.501 5.501 0 016.706 0z"/><path d="M11.828 14.306l.005-.106a1.1 1.1 0 012.195-.106l.005.106a1.1 1.1 0 002.2 0 3.3 3.3 0 10-6.6 0 1.1 1.1 0 002.195.106zm17.6 0l.005-.106a1.1 1.1 0 012.195-.106l.005.106a1.1 1.1 0 002.2 0 3.3 3.3 0 10-6.6 0 1.1 1.1 0 002.195.106zm1.105 5.394a1.1 1.1 0 011.1 1.1c0 5.468-4.432 9.9-9.9 9.9-5.467 0-9.9-4.433-9.9-9.9a1.1 1.1 0 011.1-1.1h17.6z" fill="#00292B" fill-rule="nonzero"/></g></svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" v-else-if="type == 'happy'" v-bind:svg-inline="''" v-bind:src="'@/assets/images/motivation/happy.svg'" v-bind:alt="'Happy'"><g fill="none" fill-rule="evenodd"><path fill="#FFD220" d="M6.706 0h30.588a5.501 5.501 0 015.498 5.328l1.03 33a5.5 5.5 0 01-5.325 5.67l-.172.002H5.675a5.5 5.5 0 01-5.5-5.5c0-.057 0-.115.002-.172l1.031-33A5.501 5.501 0 016.706 0z"/><g fill="#00292B" transform="translate(10 11)"><path fill-rule="nonzero" d="M17.422 11.999a7.685 7.685 0 01-5.732 2.568 7.673 7.673 0 01-5.737-2.565 1.1 1.1 0 00-1.639 1.468 9.877 9.877 0 007.376 3.297 9.884 9.884 0 007.37-3.299 1.1 1.1 0 00-1.638-1.469z"/><circle cx="2.933" cy="2.467" r="1.467"/><circle cx="20.533" cy="2.467" r="1.467"/></g></g></svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" v-else-if="type == 'meh'" v-bind:svg-inline="''" v-bind:src="'@/assets/images/motivation/meh.svg'" v-bind:alt="'Meh'"><g fill="none" fill-rule="evenodd"><path fill="#FFD220" d="M6.706 0h30.588a5.501 5.501 0 015.498 5.328l1.03 33a5.5 5.5 0 01-5.325 5.67l-.172.002H5.675a5.5 5.5 0 01-5.5-5.5c0-.057 0-.115.002-.172l1.031-33A5.501 5.501 0 016.706 0z"/><g fill="#00292B" transform="translate(10 11)"><path fill-rule="nonzero" d="M1.833 16.4h2.934a1.1 1.1 0 000-2.2H1.833a1.1 1.1 0 000 2.2z"/><circle cx="2.933" cy="2.467" r="1.467"/><circle cx="20.533" cy="2.467" r="1.467"/></g></g></svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" v-else-if="type == 'sad'" v-bind:svg-inline="''" v-bind:src="'@/assets/images/motivation/sad.svg'" v-bind:alt="'Sad'"><g fill="none" fill-rule="evenodd"><path fill="#FFD220" d="M6.706 0h30.588a5.501 5.501 0 015.498 5.328l1.03 33a5.5 5.5 0 01-5.325 5.67l-.172.002H5.675a5.5 5.5 0 01-5.5-5.5c0-.057 0-.115.002-.172l1.031-33A5.501 5.501 0 016.706 0z"/><g fill="#00292B" transform="translate(10 11)"><path fill-rule="nonzero" d="M15.699 15.852a7.674 7.674 0 00-4.228-1.286c-1.54 0-3.009.452-4.261 1.286a1.1 1.1 0 01-1.22-1.831 9.861 9.861 0 015.481-1.655c1.945 0 3.822.584 5.438 1.648a1.1 1.1 0 01-1.21 1.838z"/><circle cx="2.933" cy="2.467" r="1.467"/><circle cx="20.533" cy="2.467" r="1.467"/></g></g></svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" v-else-if="type == 'awful'" v-bind:svg-inline="''" v-bind:src="'@/assets/images/motivation/awful.svg'" v-bind:alt="'Awful'"><g fill="none" fill-rule="evenodd"><path fill="#FFD220" d="M6.706 0h30.588a5.501 5.501 0 015.498 5.328l1.03 33a5.5 5.5 0 01-5.325 5.67l-.172.002H5.675a5.5 5.5 0 01-5.5-5.5c0-.057 0-.115.002-.172l1.031-33A5.501 5.501 0 016.706 0z"/><g fill="#00292B" transform="translate(10 11)"><circle cx="2.933" cy="2.467" r="1.467"/><circle cx="20.533" cy="2.467" r="1.467"/><path stroke="#00292B" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" d="M16.867 16.4c0-3.888-1.983-5.867-5.133-5.867S6.6 12.512 6.6 16.4c1.322-1.173 3.033-1.76 5.133-1.76s3.812.587 5.134 1.76z"/></g></g></svg>
</template>

<script>
const badgeTypes = ['awesome', 'happy', 'meh', 'sad', 'awful'];

export default {
  name: 'UserMotivation',
  props: {
    type: {
      type: String,
      default: 'awesome',
      validator(value) {
        return badgeTypes.includes(value);
      }
    }
  }
};
</script>

import { defineComponent } from 'vue';
import componentI18n from '@/i18n/componentI18n';
import GroupHeader from '@/components/groups/GroupHeader.vue';
import Topics from '@/components/topics/Topics.vue';
export default defineComponent({
    components: {
        GroupHeader,
        Topics
    },
    ...componentI18n('components.groups'),
    props: {
        allowDrop: { type: Boolean, default: true },
        canRename: { type: Boolean, default: false },
        group: { type: Object, required: true },
        showGroupNumber: { type: Boolean, default: true },
        showShareHereButton: { type: Boolean, default: false },
        showVotes: { type: Boolean, default: true },
        sortedGroupTopics: { type: Array, required: true },
        userRenaming: { type: Object, default: null },
        users: { type: Array, required: true }
    },
    data() {
        return {
            open: false
        };
    },
    computed: {
        votesText() {
            this.users;
            return this.group.voters
                .map((voter) => voter.id)
                .map((id) => this.users.find((u) => u.id === id)?.name)
                .join(', ')
                .replace(/,(?=[^,]*$)/, ` ${this.$t('common.and')}`);
        }
    },
    methods: {
        handleAssign() {
            this.$emit('assign');
        },
        handleRename(newName) {
            this.$emit('rename', newName);
        },
        handleStartRenaming() {
            this.$emit('start-rename');
        },
        handleDrop(event) {
            this.$emit('drop', event);
        }
    }
});

import constants from '@/constants';
import lists from '@/helpers/lists';
const listPrefix = () => ' -';
const h1 = (text) => `# ${text}\n`;
const h2 = (text) => `\n## ${text}\n`;
const h3 = (text) => `### ${text}\n`;
const li = (text) => `${listPrefix()} ${text}\n`;
const nestedLi = (text) => `    ${listPrefix()} ${text}\n`;
const a = (text, href) => `[${text}](${href})`;
function formatDate(date, locale) {
    return new Intl.DateTimeFormat(locale, {
        day: 'numeric',
        month: '2-digit',
        year: 'numeric'
    }).format(date);
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * Given the data of a retro, it build a markdown.
 *
 * Note: This is just an adapted copy-paste of a legacy Vuex function.
 */
export function buildRetroMarkdown({ actionItems, publicTopics, groups, users, locale, name, startsAt, url }) {
    let formatted = '';
    // compat
    const participants = users.filter((u) => u.participant);
    const topicsByType = lists.groupBy(publicTopics, 'type');
    const findUser = (id) => users.find((u) => u.id === id);
    //title
    formatted += h1(name);
    // retro url
    formatted += a('Retro Link', url);
    formatted += '\n';
    // retro date
    formatted += h3('Date ' + formatDate(new Date(startsAt), locale));
    // participants
    formatted += h2('Participants');
    participants.forEach((participant) => {
        const motivation = constants.MOTIVATION_FACES[participant.motivation]
            ? ` (${capitalizeFirstLetter(constants.MOTIVATION_FACES[participant.motivation])})`
            : '';
        formatted += li(`${participant.name}${motivation}`);
    });
    // topics
    formatted += h2('Topics');
    Object.entries(topicsByType).forEach(([type, topics]) => {
        formatted += h3(type);
        topics.forEach((topic) => {
            formatted += li(topic.description);
        });
    });
    // groups
    formatted += h2('Groups');
    groups.forEach((group) => {
        formatted += li(group.name || '');
        const groupTopics = publicTopics.filter((t) => t.groupId === group.id);
        groupTopics.forEach((topic) => {
            formatted += nestedLi(topic.description);
        });
    });
    // action items
    formatted += h2('Action Items');
    actionItems.forEach((item) => {
        let actionItemText = item.description;
        item.owners?.forEach((owner) => {
            actionItemText += `[${findUser(owner.userId).name}]`;
        });
        formatted += li(actionItemText);
    });
    return formatted;
}

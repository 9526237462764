import { defineComponent } from 'vue';
import { mapActions as mapPiniaActions } from 'pinia';
import { useRetroStore } from '@/pinia_stores/retro';
import componentI18n from '@/i18n/componentI18n';
import constants from '@/constants';
import ActionItemOwnersText from '@/components/action_items/ActionItemOwnersText.vue';
import ActionItemOwnerAvatars from '@/components/action_items/ActionItemOwnerAvatars.vue';
import ActionItemTemplate from '@/components/action_items/ActionItemTemplate.vue';
export default defineComponent({
    name: 'EditActionItem',
    components: {
        ActionItemTemplate,
        ActionItemOwnersText,
        ActionItemOwnerAvatars
    },
    ...componentI18n('components.action_items.edit'),
    props: {
        item: { type: Object, required: true }
    },
    data() {
        return {
            description: this.item.description
        };
    },
    computed: {
        noChanges() {
            return this.description.trim() && this.description === this.item.description;
        },
        submitDisabled() {
            return this.noChanges;
        },
        disabledReason() {
            if (this.noChanges) {
                return this.$t('no_changes');
            }
            else {
                return null;
            }
        },
        maxLength() {
            return constants.MAX_ACTION_ITEM_LENGTH;
        },
        charactersRemaining() {
            return this.maxLength - this.description.length;
        },
        showCharactersRemaining() {
            return constants.LENGTH_REMINDER > this.charactersRemaining;
        }
    },
    watch: {
        description(newValue) {
            // TODO(UPGRADE): When cancelling the edit, this sends a writeActionItem
            // with an empty description.
            if (newValue) {
                this.broadcastActionItemWrite({ description: newValue });
            }
        }
    },
    methods: {
        ...mapPiniaActions(useRetroStore, [
            'updateActionItem',
            'broadcastActionItemWrite',
            'stopEditingActionItem'
        ]),
        handleSubmit() {
            if (this.description) {
                this.updateActionItem({
                    itemId: this.item.id,
                    description: this.description
                });
            }
            else {
                this.cancelEditing();
            }
        },
        cancelEditing() {
            this.description = '';
            this.stopEditingActionItem();
        }
    }
});

<template>
  <div>
    <div class="flex">
      <pill-toggle
        class="mx-1"
        width="14.5rem"
        :pressed="item.done"
        @on="handleMarkDone(true)"
        @off="handleMarkDone(false)"
      >
        {{ $t('done') }}
      </pill-toggle>

      <transition name="fade">
        <pill-toggle
          v-if="item.done"
          class="mx-1"
          width="12rem"
          :pressed="item.useful"
          @on="handleMarkUseful(true)"
          @off="handleMarkUseful(false)"
        >
          {{ $t('useful') }}
        </pill-toggle>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import componentI18n from '@/i18n/componentI18n';

export default {
  name: 'ActionItem',
  ...componentI18n('components.action_items.follow_up'),
  props: {
    item: { type: Object, required: true }
  },
  methods: {
    ...mapActions(['markActionItemDone', 'markActionItemUseful']),
    handleMarkDone(done) {
      this.markActionItemDone({ itemId: this.item.id, done });
      if (!done && this.item.useful) {
        this.markActionItemUseful({ itemId: this.item.id, useful: false });
      }
    },
    handleMarkUseful(useful) {
      this.markActionItemUseful({ itemId: this.item.id, useful });
    }
  }
};
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter-from {
  opacity: 0;
}
</style>

import { defineComponent } from 'vue';
import { mapActions as mapPiniaActions } from 'pinia';
import { useRetroStore } from '@/pinia_stores/retro';
import componentI18n from '@/i18n/componentI18n';
import constants from '@/constants';
import ActionItemTemplate from '@/components/action_items/ActionItemTemplate.vue';
export default defineComponent({
    name: 'NewActionItem',
    components: {
        ActionItemTemplate
    },
    ...componentI18n('components.action_items.new'),
    props: {
        groupId: { type: Number, required: false, default: null }
    },
    data() {
        return {
            description: ''
        };
    },
    computed: {
        descriptionEmpty() {
            return !this.description.trim();
        },
        submitDisabled() {
            return this.descriptionEmpty;
        },
        disabledReason() {
            if (this.descriptionEmpty) {
                return this.$t('description_empty');
            }
            else {
                return null;
            }
        },
        maxLength() {
            return constants.MAX_ACTION_ITEM_LENGTH;
        },
        charactersRemaining() {
            return this.maxLength - this.description.length;
        },
        showCharactersRemaining() {
            return constants.LENGTH_REMINDER > this.charactersRemaining;
        }
    },
    watch: {
        description(newValue) {
            this.broadcastActionItemWrite({ description: newValue });
        }
    },
    mounted() {
        this.$refs.input.focus();
    },
    methods: {
        ...mapPiniaActions(useRetroStore, [
            'createActionItem',
            'broadcastActionItemWrite',
            'stopWritingActionItem'
        ]),
        handleActionItemWritten() {
            if (!this.description) {
                return;
            }
            this.createActionItem({
                groupId: this.groupId,
                description: this.description
            });
        },
        cancel() {
            this.description = '';
            this.stopWritingActionItem();
        }
    }
});

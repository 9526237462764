import constants from '@/constants';
import moment from 'moment';
const HOUR_TO_SECONDS = 3600;
const MINUTE_TO_SECONDS = 60;
const SECOND_TO_MILLISECONDS = 1000;
export default {
    name: 'Timer',
    props: {
        minutes: { type: Number, default: 0 },
        seconds: { type: Number, default: 0 },
        startedAt: { type: [Object, String], default: null },
        textSize: {
            type: String,
            default: 'small',
            validator(value) {
                return ['small', 'base', 'large'].includes(value);
            }
        }
    },
    data() {
        return {
            countdown: {
                hours: 0,
                minutes: 0,
                seconds: 0,
                negative: false
            },
            interval: null
        };
    },
    computed: {
        endsAtDate() {
            return moment(this.startedAt)
                .add(this.minutes, 'minutes')
                .add(this.seconds, 'seconds')
                .toDate();
        }
    },
    mounted() {
        this.startTimer();
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    methods: {
        startTimer() {
            if (this.startedAt && !this.interval) {
                this.interval = setInterval(this.updateTimer, constants.TIMER_FREQ);
                this.updateTimer();
            }
        },
        updateTimer() {
            const now = new Date().getTime();
            const distanceInMilliseconds = this.endsAtDate.getTime() - now;
            const secondsLeft = Math.floor(distanceInMilliseconds / SECOND_TO_MILLISECONDS);
            this.countdown.negative = secondsLeft < 0;
            let absoluteSeconds = Math.abs(secondsLeft);
            this.countdown.hours = Math.floor(absoluteSeconds / HOUR_TO_SECONDS);
            absoluteSeconds -= this.countdown.hours * HOUR_TO_SECONDS;
            this.countdown.minutes = Math.floor(absoluteSeconds / MINUTE_TO_SECONDS);
            absoluteSeconds -= this.countdown.minutes * MINUTE_TO_SECONDS;
            this.countdown.seconds = Math.floor(absoluteSeconds);
        }
    }
};

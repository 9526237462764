import { defineComponent } from 'vue';
import { mapState as mapPiniaState } from 'pinia';
import { mapState, mapGetters } from 'vuex';
import { useRetroStore } from '@/pinia_stores/retro';
import componentI18n from '@/i18n/componentI18n';
import ActionItems from '@/components/action_items/ActionItems.vue';
import Groups from '@/components/groups/Groups.vue';
import JoinRetro from '@/components/JoinRetro.vue';
import Loading from '@/components/Loading.vue';
import MotivationForm from '@/components/MotivationForm.vue';
import RetroBrief from '@/components/phases/RetroBrief.vue';
import ReviewPhase from '@/components/phases/ReviewPhase.vue';
import SetupPhase from '@/components/phases/SetupPhase.vue';
import WritingPhase from '@/components/phases/WritingPhase.vue';
import FreeIceBreakerModal from '@/components/ice_breakers/FreeIceBreakerModal.vue';
import transitions from '@/helpers/transitions';
export default defineComponent({
    components: {
        ActionItems,
        FreeIceBreakerModal,
        Groups,
        JoinRetro,
        Loading,
        MotivationForm,
        RetroBrief,
        ReviewPhase,
        SetupPhase,
        WritingPhase
    },
    ...componentI18n('views.retro.show'),
    props: {
        isFreeIceBreakerModalOpen: { type: Boolean, required: true }
    },
    computed: {
        ...mapPiniaState(useRetroStore, [
            'isLoaded',
            'isMotivationPending',
            'isRetroUser',
            'phase',
            'progressModal',
            'activity',
            'currentGroup',
            'phase',
            'isModerator',
            'currentGroupActionItems',
            'hasJoinedTheRetro'
        ]),
        ...mapState(['retro']),
        ...mapGetters(['loggedIn']),
        showDiscussionGroups() {
            return this.phase === 'discussion' && (!this.currentGroup || !!this.progressModal);
        }
    },
    methods: {
        ...transitions.fade,
        handleIsFreeIceBreakerModalOpen(value) {
            this.$emit('update:isFreeIceBreakerModalOpen', value);
        }
    },
    compatConfig: {
        COMPONENT_V_MODEL: false
    }
});

import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useRetroStore } from '@/pinia_stores/retro';
import componentI18n from '@/i18n/componentI18n';
import constants from '@/constants';
import IceBreakerDialog from './IceBreakerDialog.vue';
import IceBreakerPostIt, { colors as postItColors } from './IceBreakerPostIt.vue';
export default defineComponent({
    ...componentI18n('components.lobby.ice_breakers.answering_dialog'),
    components: {
        IceBreakerDialog,
        IceBreakerPostIt
    },
    data() {
        return {
            answer: ''
        };
    },
    computed: {
        ...mapState(useRetroStore, ['iceBreakerAnswers', 'iceBreakerQuestion', 'isSomeActionLoading']),
        answerInputError() {
            return this.answer.length > constants.MAX_ICE_BREAKER_ANSWER_LENGTH
                ? this.$t('input_error')
                : null;
        },
        sendButtonDisabled() {
            return this.answer.trim() === '' || this.answerInputError || this.isSomeActionLoading;
        },
        getMaxLength() {
            return constants.MAX_ICE_BREAKER_ANSWER_LENGTH;
        }
    },
    methods: {
        ...mapActions(useRetroStore, ['sendIceBreakerAnswer']),
        postItColor(index) {
            return postItColors[index % postItColors.length];
        },
        sendAnswer() {
            if (!this.sendButtonDisabled) {
                this.sendIceBreakerAnswer(this.answer);
                this.answer = '';
            }
        }
    }
});

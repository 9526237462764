function prevent(e) {
  e.preventDefault();
}

export default {
  preventScrolling() {
    const body = document.body;
    body.classList.add('stop-scrolling');
    body.addEventListener('touchmove', prevent, { passive: false });
  },
  allowScrolling() {
    const body = document.body;
    body.classList.remove('stop-scrolling');
    body.removeEventListener('touchmove', prevent);
  }
};

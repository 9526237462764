import Timer from '@/components/Timer.vue';
import SvgIcon from '@/components/SvgIcon.vue';
export default {
    name: 'BaseParticipantSidebarControl',
    components: {
        Timer,
        SvgIcon
    },
    props: {
        buttonLoading: { type: Boolean, default: false },
        buttonText: { type: String, default: '' },
        buttonType: {
            type: String,
            default: 'none',
            validator: (value) => {
                return ['normal', 'undo', 'none'].includes(value);
            }
        },
        hasTimer: { type: Boolean, default: false },
        image: { type: String, default: null },
        subtitle: { type: String, default: '' },
        timerMinutes: { type: Number, default: 0 },
        timerStartedAt: { type: String, default: null },
        title: { type: String, default: '' }
    },
    computed: {
        hasNoButton() {
            return this.buttonType === 'none';
        },
        hasNormalButton() {
            return this.buttonType === 'normal';
        },
        hasUndoButton() {
            return this.buttonType === 'undo';
        }
    },
    methods: {
        handleButton() {
            this.$emit('click');
        }
    }
};

<template>
  <div class="flex flex-col items-center justify-center h-full mx-5 text-sm font-medium sm:pt-8">
    <section-heading v-if="tooEarly" :subheading="tooEarlyMessage" />
    <template v-else>
      <section-heading :heading="$t('title')" :subheading="$t('description')" />
      <motivation-picker v-model="motivation" class="my-10" />
      <pill-button
        v-tooltip="disabledReason"
        :disabled="submitDisabled"
        @click="handleSubmitMotivation"
      >
        {{ $t('submit') }}
      </pill-button>
      <div class="flex w-full gap-2 my-8 max-w-180">
        <text-field
          class="flex-1"
          :model-value="link"
          :label="$t('retro_link')"
          required
          disabled
        />
        <div class="self-end mb-2">
          <button class="align-middle" @click="copyLink">
            <svg-icon name="copy" class="dark:text-gray-300" />
          </button>
          <span v-if="showCopiedText" class="absolute ml-3 text-primary-900 dark:text-gray-300">{{
            $t('link_copied')
          }}</span>
        </div>
      </div>
      <div v-if="callLink" class="text-primary-900 dark:text-gray-300 sm:pb-8">
        <span>{{ $t('join_call_text_1') }}</span>
        <a
          :href="callLink"
          target="_blank"
          rel="noopener noreferrer"
          class="text-primary-600 dark:text-blue-skyblue"
        >
          {{ $t('join_call_link') }}</a
        >
        <span>{{ $t('join_call_text_2') }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import componentI18n from '@/i18n/componentI18n';
import MotivationPicker from '@/components/MotivationPicker';
import SvgIcon from '@/components/SvgIcon.vue';
import moment from 'moment';

import constants from '@/constants';
import { useRetroStore } from '@/pinia_stores/retro';

export default {
  name: 'MotivationForm',
  components: {
    MotivationPicker,
    SvgIcon
  },
  ...componentI18n('components.motivation_form'),
  data() {
    return {
      motivation: null,
      showCopiedText: false
    };
  },
  computed: {
    ...mapState(useRetroStore, ['startsAt', 'callLink']),
    tooEarly() {
      return moment(this.startsAt).diff(moment(), 'hours') > constants.EARLY_JOINING_THRESHOLD;
    },
    submitDisabled() {
      return this.motivation === null;
    },
    disabledReason() {
      return this.submitDisabled ? this.$t('no_motivation') : null;
    },
    tooEarlyMessage() {
      const futureDay = moment().diff(moment(this.startsAt), 'days') === 0;

      return this.$t('too_early', {
        datetime: futureDay ? this.ltStartsAt() : this.timeOnDate()
      });
    },
    link() {
      return `${window.location.origin}${this.$route.path}`;
    }
  },
  methods: {
    ...mapActions(useRetroStore, ['submitMotivation']),
    handleSubmitMotivation() {
      this.submitMotivation(this.motivation);
    },
    ltStartsAt() {
      return moment(this.startsAt).format('LT');
    },
    timeOnDate() {
      const llStartsAt = moment(this.startsAt).format('ll');

      return this.$t('time_on_date', { time: this.ltStartsAt(), date: llStartsAt });
    },
    copyLink() {
      this.$clipboard(this.link);
      this.showCopiedText = true;
      const COPY_TIMEOUT = 5000;
      setTimeout(() => {
        this.showCopiedText = false;
      }, COPY_TIMEOUT);
    }
  }
};
</script>

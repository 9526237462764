<template>
  <div class="relative">
    <template v-if="hasFocusedUsers">
      <div
        class="absolute top-0 right-0 z-20 px-2 -mt-5 text-sm text-white bg-gray-400 rounded-t-sm dark:bg-gray-500"
      >
        <span v-for="focusedUser in focusedUsers" :key="focusedUser.id">
          {{ focusedUser.name }}
        </span>
      </div>
      <div
        class="absolute top-0 left-0 right-0 z-10 h-1 ml-2 bg-gray-400 rounded-lg rounded-tr-none dark:bg-gray-500"
      />
    </template>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UserFocus',
  props: {
    focusedUsers: { type: Array, default: () => [] }
  },
  computed: {
    hasFocusedUsers() {
      return this.focusedUsers.length > 0;
    }
  }
};
</script>

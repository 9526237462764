import Draggable from 'vuedraggable';
import Topic from '@/components/topics/Topic.vue';
export default {
    name: 'Topics',
    components: {
        Draggable,
        Topic
    },
    props: {
        allowDrop: { type: [Boolean, Function], default: false },
        stacked: { type: Boolean, default: false },
        topics: { type: Array, required: true }
    },
    data() {
        return {
            safeTopics: this.topics
        };
    },
    watch: {
        topics: {
            handler(newValue) {
                this.safeTopics = newValue;
            },
            deep: true
        }
    },
    methods: {
        handleDrag(event) {
            this.$emit('drag', event);
        },
        handleDragEnd(event) {
            this.$emit('dragEnd', event);
        },
        handleDrop(event) {
            this.$emit('drop', event);
        }
    }
};

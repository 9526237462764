<template>
  <div v-tooltip="liveValue">
    <input
      v-model="liveValue"
      type="text"
      class="w-full px-2 mb-1 text-lg font-medium truncate bg-gray-200 rounded italic-placeholder group-name-input text-primary-900 dark:bg-gray-900 dark:text-gray-300"
      :class="{ 'cursor-not-allowed': disabled }"
      :placeholder="$t('default')"
      :disabled="disabled"
      :maxlength="maxGroupNameLength"
      @blur="() => saveGroupName()"
      @focus="() => startChangingGroupName()"
    />
  </div>
</template>

<script>
import componentI18n from '@/i18n/componentI18n';

export default {
  name: 'GroupNameInput',
  ...componentI18n('components.groups.name'),
  props: {
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      maxGroupNameLength: 150,
      liveValue: this.value
    };
  },
  watch: {
    value() {
      this.liveValue = this.value;
    }
  },
  methods: {
    truncateGroupName(value) {
      return value.substring(0, this.maxGroupNameLength);
    },
    deleteExtraSpaces(value) {
      return value.trim().replace(/ +(?= )/g, '');
    },
    startChangingGroupName() {
      this.$emit('focus');
    },
    saveGroupName() {
      this.liveValue = this.liveValue
        ? this.truncateGroupName(this.deleteExtraSpaces(this.liveValue))
        : this.liveValue;
      this.$emit('input', this.liveValue);
    }
  }
};
</script>

import { defineComponent } from 'vue';
import { mapState as mapPiniaState } from 'pinia';
import Timer from '@/components/Timer.vue';
import componentI18n from '@/i18n/componentI18n';
import { useRetroStore } from '@/pinia_stores/retro';
export default defineComponent({
    ...componentI18n('components.sidebar.moderator_control'),
    components: {
        Timer
    },
    props: {
        hasTimer: { type: Boolean, default: false },
        highlightSubtitleInsteadOfTitle: { type: Boolean, default: false },
        subtitle: { type: String, default: '' },
        timerMinutes: { type: Number, default: 0 },
        timerStartedAt: { type: String, default: null },
        title: { type: String, default: '' },
        buttonText: { type: String, default: '' },
        hasButton: { type: Boolean, default: false },
        image: { type: String, default: null }
    },
    computed: {
        ...mapPiniaState(useRetroStore, ['expectedDurationInMinutes', 'writingStartedAt'])
    },
    methods: {
        handleButton() {
            this.$emit('click');
        }
    }
});

import { defineComponent } from 'vue';
import { mapWritableState, mapActions } from 'pinia';
import { useRetroStore } from '@/pinia_stores/retro';
import constants from '@/constants';
import componentI18n from '@/i18n/componentI18n';
import IceBreakerDialog from './IceBreakerDialog.vue';
export default defineComponent({
    ...componentI18n('components.lobby.ice_breakers.asking_dialog'),
    components: {
        IceBreakerDialog
    },
    data() {
        return {
            question: ''
        };
    },
    computed: {
        ...mapWritableState(useRetroStore, ['isPreparingIceBreaker']),
        askInputError() {
            return this.question.length > constants.MAX_ICE_BREAKER_QUESTION_LENGTH
                ? this.$t('input_error')
                : null;
        },
        sendButtonDisabled() {
            return this.question.trim() === '' || this.askInputError;
        },
        getMaxLength() {
            return constants.MAX_ICE_BREAKER_QUESTION_LENGTH;
        }
    },
    methods: {
        ...mapActions(useRetroStore, ['sendIceBreakerQuestion']),
        cancel() {
            this.isPreparingIceBreaker = false;
        },
        sendQuestion() {
            if (!this.sendButtonDisabled) {
                this.sendIceBreakerQuestion(this.question);
            }
        }
    }
});

import ModalDialog from '@/components/ModalDialog.vue';
import ParticipantContextMenu from '@/components/users/ParticipantContextMenu.vue';
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapWritableState as mapPiniaWritableState, mapActions as mapPiniaActions } from 'pinia';
import { mapGetters } from 'vuex';
import componentI18n from '@/i18n/componentI18n';
import { useRetroStore } from '@/pinia_stores/retro';
export default defineComponent({
    components: {
        ModalDialog,
        ParticipantContextMenu
    },
    props: {
        participant: { type: Object, required: true }
    },
    ...componentI18n('components.users'),
    data() {
        return {
            isHovered: false,
            showRemoveModal: false
        };
    },
    computed: {
        ...mapPiniaWritableState(useRetroStore, ['progressModal']),
        ...mapPiniaState(useRetroStore, ['phase', 'currentPosterId', 'isModerator']),
        ...mapGetters(['session']),
        badge() {
            return this?.participant?.badgeType ? this.$t(`badges.${this.participant.badgeType}`) : '';
        },
        canRemoveParticipant() {
            return (this.participant && !this.isParticipantAModerator && this.participant.id !== this.session.id);
        },
        isCurrentPoster() {
            return !!this.participant && this.participant.id === this.currentPosterId;
        },
        doneVoting() {
            return this.participant?.doneVoting && this.phase === 'voting';
        },
        doneWriting() {
            return this.participant?.doneWriting && this.phase === 'writing';
        },
        isParticipantAModerator() {
            return this.participant?.moderator;
        },
        itemType() {
            if (this.isParticipantAModerator) {
                return 'participantModerator';
            }
            else {
                return 'participant';
            }
        },
        name() {
            return this.participant ? this.participant.name : this.$t('empty_slot');
        },
        notOkWithGroups() {
            return this.phase === 'grouping' && this.participant?.okWithGroups === false;
        },
        showMenuDots() {
            return (this.isHovered &&
                this.isModerator &&
                (!this.participant ||
                    !this.isParticipantAModerator ||
                    this.isCurrentPoster ||
                    this.canRemoveParticipant));
        }
    },
    methods: {
        ...mapPiniaActions(useRetroStore, ['turnUserIntoAModerator', 'kickUser']),
        handleEndTurn() {
            this.progressModal = 'endTurn';
        },
        handleMakeModerator() {
            this.turnUserIntoAModerator(this.participant.id);
        },
        handleRemoveParticipant() {
            this.showRemoveModal = true;
        },
        hideRemoveModal() {
            this.showRemoveModal = false;
        }
    }
});

import { mapState as mapPiniaState, mapWritableState as mapPiniaWritableState } from 'pinia';
import { useRetroStore } from '@/pinia_stores/retro';
import componentI18n from '@/i18n/componentI18n';
import ModalDialog from '@/components/ModalDialog.vue';
import Timer from '@/components/Timer.vue';
export default {
    ...componentI18n('components.progress.dialogs.choose_review_or_next_group'),
    components: {
        ModalDialog,
        Timer
    },
    computed: {
        ...mapPiniaWritableState(useRetroStore, ['progressModal']),
        ...mapPiniaState(useRetroStore, [
            'writingStartedAt',
            'expectedDurationInMinutes',
            'currentGroupNumber'
        ]),
        isVisible() {
            return this.progressModal === 'reviewOrNextGroup';
        },
        primaryActions() {
            const nextGroupNumber = (this.currentGroupNumber || 0) + 1;
            return {
                [this.$t('discuss_group_n', { n: nextGroupNumber })]: () => (this.progressModal = 'nextGroupTiming')
            };
        },
        secondaryActions() {
            return {
                [this.$t('cancel')]: () => (this.progressModal = null)
            };
        },
        tertiaryActions() {
            return {
                [this.$t('go_to_review')]: () => (this.progressModal = 'nextPhase')
            };
        }
    }
};

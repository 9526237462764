import { defineComponent } from 'vue';
import componentI18n from '@/i18n/componentI18n';
export default defineComponent({
    name: 'ParticipantContextMenu',
    props: {
        isCurrentPoster: { type: Boolean, required: true },
        type: {
            type: String,
            required: true,
            validator: (value) => ['participant', 'participantModerator'].includes(value)
        }
    },
    ...componentI18n('components.users.context'),
    data() {
        return {
            showContextMenu: false
        };
    },
    computed: {
        isOnlyParticipant() {
            return this.type === 'participant';
        }
    },
    methods: {
        endTurn() {
            this.$emit('endTurn');
            this.showContextMenu = false;
        },
        makeParticipantModerator() {
            this.$emit('makeModerator');
            this.showContextMenu = false;
        },
        removeParticipant() {
            this.$emit('removeParticipant');
            this.showContextMenu = false;
        }
    }
});

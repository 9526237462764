const colorMappings = {
    orange: 'bg-orange-500',
    blue: 'bg-teal-500',
    yellow: 'bg-yellow-500',
    pink: 'bg-pink-400',
    darkOrange: 'bg-orange-600',
    red: 'bg-red-500'
};
export const colors = Object.keys(colorMappings);
export default {
    name: 'IceBreakerPostIt',
    props: {
        text: { type: String, required: true },
        color: { type: String, required: true }
    },
    data() {
        return {
            colorMappings
        };
    }
};

import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapActions as mapPiniaActions } from 'pinia';
import BaseModeratorSidebarControl from '@/components/sidebar/BaseModeratorSidebarControl.vue';
import componentI18n from '@/i18n/componentI18n';
import { useRetroStore } from '@/pinia_stores/retro';
export default defineComponent({
    ...componentI18n('components.sidebar.moderator_control'),
    components: {
        BaseModeratorSidebarControl
    },
    computed: {
        ...mapPiniaState(useRetroStore, [
            'currentGroup',
            'phase',
            'postingDuration',
            'postingStartedAt',
            'votingMechanic',
            'writingDuration',
            'writingStartedAt'
        ]),
        discussionSubtitle() {
            if (this.currentGroup) {
                if (this.currentGroup.name) {
                    return this.$i18n.t('discuss.subtitle_with_group_name', {
                        groupName: this.currentGroup.name
                    });
                }
                else {
                    return this.$i18n.t('discuss.subtitle_with_group_number', {
                        groupNumber: this.currentGroup.number
                    });
                }
            }
            return this.$i18n.t('discuss.subtitle_without_group');
        },
        votingImage() {
            const oneAndTwoImage = require('@/assets/images/sidebarModeratorControl/voting-one-and-two-dude.svg');
            const likeImage = require('@/assets/images/sidebarModeratorControl/voting-like-dude.svg');
            return this.votingMechanic === 'like' ? likeImage : oneAndTwoImage;
        }
    },
    methods: {
        ...mapPiniaActions(useRetroStore, ['addTimeToCurrentGroup'])
    }
});

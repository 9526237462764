export default function delightedSurvey({ activity, email, name, retroId }) {
  const apiKey = process.env.VUE_APP_SURVEY_KEY;
  if (!apiKey) {
    return;
  }

  let delightedSurvey = document.createElement('script');
  delightedSurvey.setAttribute('type', 'text/javascript');
  delightedSurvey.innerHTML = `!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var l=t.getElementsByTagName("script")[0];l.parentNode.insertBefore(o,l)}}(window,document,"${apiKey}","delighted"); `;
  document.head.appendChild(delightedSurvey);

  window.delighted.survey({
    email,
    name,
    properties: {
      activity,
      retroId
    }
  });
}

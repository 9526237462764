import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { mapState as mapPiniaState, mapWritableState as mapPiniaWritableState, mapActions as mapPiniaActions } from 'pinia';
import Reactions from '@/components/topics/Reactions.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import TopicTemplate from '@/components/topics/TopicTemplate.vue';
import componentI18n from '@/i18n/componentI18n';
import constants from '@/constants';
import transitions from '@/helpers/transitions';
import { useRetroStore } from '@/pinia_stores/retro';
export default defineComponent({
    components: {
        SvgIcon,
        Reactions,
        TopicTemplate
    },
    ...componentI18n('components.topics'),
    props: {
        topic: { type: Object, required: true },
        stacked: { type: Boolean, default: false },
        focusedUsers: { type: Array, default: () => [] }
    },
    computed: {
        ...mapPiniaWritableState(useRetroStore, ['topicBeingDeletedId', 'topicBeingEditedId']),
        ...mapPiniaState(useRetroStore, [
            'activity',
            'currentPosterId',
            'isCurrentUserPosting',
            'isModerator',
            'isParticipant',
            'isReadyToShare',
            'isSomeActionLoading',
            'isWritingTopic',
            'phase',
            'topicBeingShared',
            'topicBeingSharedId',
            'users'
        ]),
        ...mapGetters(['session']),
        user() {
            return this.users.find((u) => u.id === this.topic.userId) ?? null;
        },
        highlight() {
            return ((this.phase === 'writing' && this.isParticipant) ||
                (this.topic.userId === this.currentPosterId &&
                    (this.topic.public || !this.otherBeingShared)) ||
                this.isBeingShared ||
                (this.phase === 'grouping' && this.isModerator));
        },
        stripeColor() {
            const [name, shade] = this.activity.topicTypes
                .find((t) => t.id === this.topic.type)
                .color.split('-');
            return constants.COLORS[name][shade];
        },
        allowShare() {
            return (this.isCurrentUserPosting &&
                this.isReadyToShare &&
                !this.topicBeingShared &&
                !this.topic.public &&
                this.topic.userId === this.session.id);
        },
        allowRegrouping() {
            return ((this.topic.public &&
                this.topic.userId === this.session.id &&
                this.isCurrentUserPosting &&
                !this.topicBeingShared) ||
                (this.phase === 'grouping' && this.isModerator));
        },
        canEdit() {
            return (!this.isBeingEdited &&
                !this.otherBeingEdited &&
                !this.isBeingDeleted &&
                !this.otherBeingDeleted &&
                !this.isSomeActionLoading &&
                this.isWritingTopic);
        },
        isBeingEdited() {
            return this.topic.id === this.topicBeingEditedId;
        },
        isBeingShared() {
            return this.topic.id === this.topicBeingSharedId;
        },
        isBeingDeleted() {
            return this.topic.id === this.topicBeingDeletedId;
        },
        otherBeingEdited() {
            return this.topicBeingEditedId && this.topicBeingEditedId !== this.topic.id;
        },
        otherBeingDeleted() {
            return this.topicBeingDeletedId && this.topicBeingDeleted !== this.topic.id;
        },
        otherBeingShared() {
            return this.topicBeingSharedId && this.topicBeingSharedId !== this.topic.id;
        }
    },
    methods: {
        ...mapPiniaActions(useRetroStore, ['startSharingTopic', 'stopSharingTopic']),
        ...transitions.fade,
        handleDelete() {
            this.topicBeingDeletedId = this.topic.id;
        },
        handleEdit() {
            this.topicBeingEditedId = this.topic.id;
        },
        handleShare() {
            this.startSharingTopic({ topicId: this.topic.id, mode: 'button' });
        },
        handleCancelShare() {
            this.stopSharingTopic();
        }
    }
});

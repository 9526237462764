<template>
  <!-- TODO: motivation should not be numbers. This is only to go ahead with UI development -->
  <radio-tiles
    v-model="liveValue"
    :options="options"
    class="justify-center"
    data-cy="motivation-tiles"
    active-color="topics-teal"
  >
    <template #tile="option">
      <div class="flex items-center justify-center w-full h-full">
        <user-motivation :type="option.face" class="w-16 h-16" />
      </div>
    </template>
  </radio-tiles>
</template>

<script>
import UserMotivation from '@/components/users/UserMotivation';
import constants from '@/constants';
import componentI18n from '@/i18n/componentI18n';

export default {
  name: 'MotivationPicker',
  components: {
    UserMotivation
  },
  ...componentI18n('components.motivation_picker'),
  props: {
    value: { type: String, default: null }
  },
  computed: {
    options() {
      return Object.keys(constants.MOTIVATION_FACES).map((key) => ({
        value: key,
        face: constants.MOTIVATION_FACES[key],
        label: this.$t(constants.MOTIVATION_FACES[key])
      }));
    },
    liveValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
};
</script>

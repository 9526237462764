import { mapState as mapPiniaState, mapWritableState as mapPiniaWritableState, mapActions as mapPiniaActions } from 'pinia';
import { useRetroStore } from '@/pinia_stores/retro';
import componentI18n from '@/i18n/componentI18n';
import moment from 'moment';
import ModalDialog from '@/components/ModalDialog.vue';
import Timer from '@/components/Timer.vue';
import constants from '@/constants';
export default {
    name: 'NextGroupDialog',
    components: {
        ModalDialog,
        Timer
    },
    ...componentI18n('components.progress.dialogs.next_group'),
    data() {
        return { minutesLeft: 0 };
    },
    computed: {
        ...mapPiniaState(useRetroStore, [
            'currentGroupNumber',
            'writingStartedAt',
            'expectedDurationInMinutes'
        ]),
        ...mapPiniaWritableState(useRetroStore, ['progressModal']),
        show() {
            return this.progressModal === 'nextGroupTiming';
        },
        discussionMinutesLeft() {
            return this.minutesLeft - constants.EXPECTED_REVIEW_MINUTES;
        },
        timeOptions() {
            let options = {};
            for (const minutes of constants.SUPPORTED_TIME_OPTIONS) {
                if (minutes < this.discussionMinutesLeft) {
                    options[`${minutes} min`] = () => {
                        this.progressModal = null;
                        this.nextDiscussionGroup({ minutes });
                    };
                }
            }
            return options;
        },
        question() {
            const nextGroupNumber = (this.currentGroupNumber || 0) + 1;
            if (Object.keys(this.timeOptions).length > 0) {
                return this.$t('headings.how_much_time', {
                    n: nextGroupNumber
                });
            }
            else {
                return this.$t('headings.one_more');
            }
        },
        after() {
            if (Object.keys(this.timeOptions).length > 0) {
                return this.$t('after.add_more_time');
            }
            else {
                return this.$t('after.time_to_review');
            }
        },
        primaryActions() {
            if (Object.keys(this.timeOptions).length > 0) {
                return this.timeOptions;
            }
            else {
                return {
                    [this.$t('go_to_review')]: () => {
                        this.progressModal = 'nextPhase';
                    }
                };
            }
        },
        secondaryActions() {
            let options = {
                [this.$t('cancel')]: () => {
                    this.progressModal = null;
                }
            };
            if (Object.keys(this.timeOptions).length === 0) {
                options[this.$t('one_more')] = () => {
                    this.progressModal = null;
                    this.nextDiscussionGroup({ minutes: constants.SUPPORTED_TIME_OPTIONS[0] });
                };
            }
            return options;
        }
    },
    watch: {
        show() {
            this.setMinutesLeft();
        }
    },
    mounted() {
        this.setMinutesLeft();
    },
    methods: {
        ...mapPiniaActions(useRetroStore, ['nextDiscussionGroup']),
        setMinutesLeft() {
            const retroShouldEndAt = moment(this.writingStartedAt).add(this.expectedDurationInMinutes, 'minutes');
            this.minutesLeft = moment.duration(moment(retroShouldEndAt).diff(moment.now())).asMinutes();
        }
    }
};

export default {
  groupBy: (xs, key) => {
    if (xs?.length > 0) {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    } else {
      return {};
    }
  }
};

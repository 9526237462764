import { defineComponent } from 'vue';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia';
import { mapGetters } from 'vuex';
import VotingSection from '@/components/groups/VotingSection.vue';
import GroupTemplate from '@/components/groups/GroupTemplate.vue';
import componentI18n from '@/i18n/componentI18n';
import scrollCapture from '@/helpers/scrollCapture';
import { useRetroStore } from '@/pinia_stores/retro';
const lineHeight = 20;
const headerHeight = 60;
const marginBottomheight = 20;
const SET_HEIGHT_UNITS_TIMEOUT = 10;
export default defineComponent({
    components: {
        GroupTemplate,
        VotingSection
    },
    ...componentI18n('components.groups'),
    props: {
        group: { type: Object, required: true }
    },
    data() {
        return {
            heightUnits: 0
        };
    },
    computed: {
        ...mapPiniaState(useRetroStore, [
            'isParticipant',
            'isModerator',
            'phase',
            'currentGroupNumber',
            'topicBeingShared',
            'users',
            'isCurrentUserPosting',
            'sharingMode',
            'topics'
        ]),
        ...mapGetters(['session']),
        isCurrentGroup() {
            return (this.group.number &&
                this.group.number === this.currentGroupNumber &&
                this.phase === 'discussion');
        },
        groupTopics() {
            return this.topics
                .filter((t) => t.groupId === this.group.id)
                .sort((t1, t2) => t1.position - t2.position);
        },
        topicsLength() {
            return this.groupTopics.length;
        },
        userRenaming() {
            if (this.group.currentRenamerId && this.group.currentRenamerId !== this.session.id) {
                return this.users.find((user) => user.id === this.group.currentRenamerId);
            }
            return null;
        }
    },
    watch: {
        group() {
            this.setHeightUnits();
        },
        topicsLength() {
            this.setHeightUnits();
        }
    },
    mounted() {
        this.setHeightUnits();
    },
    beforeUnmount() {
        scrollCapture.allowScrolling();
    },
    methods: {
        ...mapPiniaActions(useRetroStore, [
            'assignTopicToGroup',
            'beginGroupRename',
            'confirmGroupRename',
            'cancelGroupRename'
        ]),
        setHeightUnits() {
            setTimeout(() => {
                this.heightUnits = Math.round((this.$refs.groupTemplate?.$refs?.topics?.$el?.clientHeight +
                    headerHeight +
                    marginBottomheight) /
                    lineHeight);
            }, SET_HEIGHT_UNITS_TIMEOUT);
        },
        handleDrop(event) {
            const topicId = event.item.attributes.id.value;
            this.handleAssign(topicId);
        },
        handleAssign(topicId) {
            this.assignTopicToGroup({
                topicId,
                groupId: this.group.id,
                position: this.groupTopics.length
            });
        },
        handleRename(newName) {
            if (this.group.currentRenamerId !== this.session.id) {
                return;
            }
            if (this.group.name !== newName) {
                this.confirmGroupRename(this.group.id, newName);
            }
            else {
                this.cancelGroupRename(this.group.id);
            }
        },
        handleStartRenaming() {
            this.beginGroupRename(this.group.id);
        }
    }
});

<template>
  <div class="relative z-0 w-76">
    <div
      class="relative flex flex-col w-full px-8 py-4 mb-6 bg-white rounded-lg h-86 shadow-200 dark:bg-gray-600"
    >
      <div class="mb-8 text-xs text-primary-400 dark:text-gray-300">{{ title }}</div>
      <div
        class="relative flex items-center h-10 px-8 mb-5 -mx-8 text-xs text-primary-500 dark:text-gray-300"
      >
        <div class="absolute top-0 left-0 -ml-5">
          <slot name="ownerAvatars" />
        </div>
        <slot name="ownersText" />
      </div>
      <div class="flex-1 mb-4">
        <slot name="body" />
      </div>
      <div class="text-xs text-gray-400 min-h-8">
        <slot name="footer" />
      </div>
    </div>
    <div class="flex items-center justify-end">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionItemTemplate',
  props: {
    title: { type: String, default: null }
  }
};
</script>

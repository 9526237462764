<template>
  <div
    class="w-full h-32 overflow-hidden bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-800"
  >
    <div class="h-full p-5 border-gray-200 border-l-7 dark:border-gray-700">
      <div class="w-16 h-3 bg-gray-200 dark:bg-gray-700" />
      <div class="w-full h-4 mt-4 bg-gray-200 dark:bg-gray-700" />
      <div class="w-2/3 h-4 mt-1 bg-gray-200 dark:bg-gray-700" />
    </div>
  </div>
</template>

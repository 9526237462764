import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapActions as mapPiniaActions, mapWritableState as mapPiniaWritableState } from 'pinia';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import componentI18n from '@/i18n/componentI18n';
import SvgIcon from '@/components/SvgIcon.vue';
import { useRetroStore } from '@/pinia_stores/retro';
export default defineComponent({
    name: 'RetroControls',
    components: {
        SvgIcon
    },
    ...componentI18n('components.progress'),
    computed: {
        ...mapGetters(['loggedIn', 'isDarkMode']),
        ...mapPiniaWritableState(useRetroStore, ['progressModal']),
        ...mapPiniaState(useRetroStore, [
            'isLoaded',
            'connectedParticipants',
            'phase',
            'isMotivationPending',
            'currentPoster',
            'groups',
            'currentGroupNumber',
            'isActionItemWritingIdle',
            'isOtherUserWritingActionItem',
            'isCurrentUserWritingActionItem',
            'isSomeActionLoading',
            'isOtherUserPosting'
        ]),
        currentPosterFirstName() {
            return this.currentPoster?.name.split(' ')[0];
        },
        buttonType() {
            return this.isDarkMode ? 'primary' : 'secondary';
        },
        loading() {
            return this.isSomeActionLoading;
        }
    },
    methods: {
        ...mapActions(['doneWriting', 'doneVoting', 'backToWriting', 'backToVoting']),
        ...mapMutations(['transition']),
        ...mapPiniaActions(useRetroStore, ['next'])
    }
});

<template>
  <pill-toggle
    v-tooltip="disabledReason"
    :pressed="pressed"
    :disabled="!enabled || loading"
    @on="handleVote"
    @off="handleUnvote"
  >
    +{{ weight }}
  </pill-toggle>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia';
import { mapGetters } from 'vuex';

import { useRetroStore } from '@/pinia_stores/retro';
import componentI18n from '@/i18n/componentI18n';

export default defineComponent({
  name: 'VoteButton',
  props: {
    group: { type: Object, required: true },
    weight: { type: Number, required: true }
  },
  ...componentI18n('components.groups.vote_button'),
  computed: {
    ...mapPiniaState(useRetroStore, ['votingMechanic', 'groups', 'loadingStatuses']),
    ...mapGetters(['session']),
    pressed() {
      return this.group.voters.some(
        (voter) => voter.id === this.session.id && voter.weight === this.weight
      );
    },
    userVotedGroup() {
      return this.group.voters.some((voter) => voter.id === this.session.id);
    },
    canVote() {
      if (this.votingMechanic === 'one_and_two') {
        return !this.usedWeight(this.weight) && !this.userVotedGroup;
      } else {
        return true;
      }
    },
    enabled() {
      return this.pressed || this.canVote;
    },
    loading() {
      return this.loadingStatuses.has('vote');
    },
    disabledReason() {
      if (this.enabled) {
        return null;
      } else if (this.userVotedGroup) {
        return this.$t('group_voted');
      } else if (this.usedWeight(this.weight)) {
        return this.$t('used_weight', { weight: this.weight });
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapPiniaActions(useRetroStore, ['voteGroup', 'unvoteGroup']),
    handleVote() {
      this.voteGroup({ groupId: this.group.id, weight: this.weight });
    },
    handleUnvote() {
      this.unvoteGroup(this.group.id);
    },
    usedWeight(weight) {
      return this.groups.some((group) => {
        return group.voters.some(
          (voter) => voter.id === this.session.id && voter.weight === weight
        );
      });
    }
  }
});
</script>
